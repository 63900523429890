/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/material-components-web@14.0.0/dist/material-components-web.min.js
 * - /npm/ulid@2.3.0/dist/index.umd.min.js
 * - /npm/moment@2.29.1/min/moment.min.js
 * - /npm/moment@2.29.1/locale/vi.js
 * - /npm/mathjs@10.0.0/lib/browser/math.min.js
 * - /npm/dialog-polyfill@0.5.6/dist/dialog-polyfill.min.js
 * - /npm/whatwg-fetch@3.6.0/dist/fetch.umd.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
